import React from "react";
import {Navigate} from "react-router-dom";

const nextPage = "/weight";

class Height extends React.Component {
    constructor(props) {
        super(props);
        this.nextPage = this.nextPage.bind(this);
        this.setInches = this.setInches.bind(this);
        this.setFeet = this.setFeet.bind(this);
        this.state = {
            feetUnits: false,
            inchesUnits: false,
            feetValid: null,
            inchesValid: null,
            redirect: false,
        }
    }
    setFeet(e) {
        this.validateFeet(e.target.value);
        this.props.onDataChange(e.target.value, 'feet');
    }
    validateFeet (feet) {
        if (feet.length > 0) this.setState({feetUnits: true});
        else this.setState({feetUnits: false});

        if (/^\d*$/.test(feet) && parseInt(feet) > 0 && parseInt(feet) <= 8) {
            this.setState({feetValid: true});
        } else {
            this.setState({feetValid: false});
        }
    }
    setInches(e) {
        this.validateInches(e.target.value);
        this.props.onDataChange(e.target.value, 'inches');
    }
    validateInches (inches) {
        if (inches.length > 0) this.setState({inchesUnits: true});
        else this.setState({inchesUnits: false});

        if (/^((0|([1-9][0-9]*))(\.[0-9]+)?)$/.test(inches) && parseInt(inches) >=0 && parseInt(inches) <= 11) {
            this.setState({inchesValid: true});
        } else {
            this.setState({inchesValid: false});
        }
    }
    nextPage () {
        if (this.state.feetValid === true && this.state.inchesValid === true)
        this.setState({redirect: true,})
    }
    componentDidMount() {
        this.props.onDataChange('height', 'page');
        if (this.props.feet) this.validateFeet(this.props.feet);
        if (this.props.inches) this.validateInches(this.props.inches);
    }

    render() {
        return (
            <div className="text-center page pageHeight">
                <h1>What is your height?</h1>

                <div className={'inputs'}>
                    <div className={'row'}>
                        <div className={'col labeledPlaceholder'}>
                            <input id={'feet'} className={'text-center' + (this.state.feetValid === false ? ' error' : '')} type={'text'}  placeholder={'Feet'}
                                   value={this.props.feet ? this.props.feet : ''}
                                   onChange={this.setFeet}
                            />
                            <label className={'labeled' + (this.state.feetUnits ? ' active' : '')}>ft.</label>
                        </div>
                        <div className={'col labeledPlaceholder'}>
                            <input id={'inches'} className={'text-center' + (this.state.inchesValid === false ? ' error' : '')} type={'text'}  placeholder={'Inches'}
                                   value={this.props.inches !== false && this.props.inches !== null && this.props.inches !== 'NaN' ? this.props.inches : ''}
                                   onChange={this.setInches}
                            />
                            <label className={'labeled' + (this.state.inchesUnits ? ' active' : '')}>in.</label>
                        </div>
                    </div>
                </div>

                <button onClick={this.nextPage} type="button" className={'btn-primary ' + (this.state.feetValid === true && this.state.inchesValid === true ? 'active' : '')}>Next -></button>
                {this.state.redirect && (
                    <Navigate to={nextPage} replace={true} />
                )}
            </div>
        )
    }
}

export default Height;
