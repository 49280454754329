import React from "react";
import {Navigate} from "react-router-dom";
import ResultChart from "./ResultChart";
import Logic from "../Logic";

const nextPage = "/needs";

class ThankYou extends React.Component {
    constructor(props) {
        super(props);
        this.nextPage = this.nextPage.bind(this);
        this.state = {
            redirect: false,
            redirectEmptyToStart: false,
            isNextAllowed: true,
        }

    }
    nextPage () {
        this.setState({redirect: true,})
    }
    componentDidMount() {
        this.props.onDataChange('thank_you', 'page');
        if (!Logic.validateState(this.props.state)) {
            this.setState({redirectEmptyToStart: true,})
        }
    }

    render() {
        let {redirect} = this.state;
        return (
            this.state.redirectEmptyToStart ? <Navigate to={'/'} replace={true} /> :
            <div className={'text-center page pageThankYou'}>
                <div className={'row'}>
                    <div className={'col left'}>
                        <h1>Thank you {this.props.state.name}!</h1>
                        <p>These are the personalized factors that affect your vitamin D needs.</p>
                    </div>
                    <div className={'col'}>
                        <ResultChart data={this.props.state} />
                        <button onClick={this.nextPage} type="button" className={'getYourResult btn-primary ' + (this.state.isNextAllowed ? 'active' : '')}>Get Your Personalized Results -></button>
                    </div>
                </div>
                {redirect && (
                    <Navigate to={nextPage} replace={true} />
                )}
            </div>
        )
    }
}

export default ThankYou;
