import React from "react";

class SpeakWithPhysician extends React.Component {
    componentDidMount() {
        this.props.onDataChange('speak_with_physician', 'page');
    }

    render() {
        return (
            <div className={'text-center page pagePhysician'}>
                <div className={'row'}>
                    <div className={'col'}>
                        <h1>It is recommended you speak with your physician about Vitamin D</h1>
                    </div>
                    <div className={'col'}>
                        <p>Because of your medical history, your Vitamin D dosing, testing and opitmization must be carefully determined and supervised by your physician.</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default SpeakWithPhysician;
