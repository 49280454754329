import React from "react";
import './ResultChart.scss';
import Logic from "../Logic";

class ResultChart extends React.Component {
    render() {
        const data = this.props.data;
        let physical = '100%';
        let lifestyle = '100%';
        let medical = '33%';

        let physicalRisk = Logic.bmiSkinProductionComposite(data);
        if (physicalRisk.level === 'Low') physical = '33%';
        else if (physicalRisk.level === 'Medium') physical = '66%';

        let sunExposure = Logic.activity({sun_exposure_weekday: data.sun_exposure_weekday, sun_exposure_weekend: data.sun_exposure_weekend});
        if (sunExposure.composite === 'Medium') lifestyle = '66%';
        else if (sunExposure.composite === 'Low') lifestyle = '33%';
        if (data.cancer === 'Yes' || data.do_you_take === 'Yes') medical = '100%';

        return (
            <div className={'charts'}>
                <div className={'header'}><label>Level of Need</label></div>
                <div className={'chart '}>
                    <label>Physical</label>
                    <div className={'chartLine'}><div className={'chartValue physical'} style={{width: physical}}></div></div>
                </div>
                <div className={'chart '}>
                    <label>Lifestyle</label>
                    <div className={'chartLine'}><div className={'chartValue lifestyle'} style={{width: lifestyle}}></div></div>
                </div>
                <div className={'chart '}>
                    <label>Medical</label>
                    <div className={'chartLine'}><div className={'chartValue medical'} style={{width: medical}}></div></div>
                </div>
            </div>
        );
    }
}

export default ResultChart;
