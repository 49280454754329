import React from "react";
import {Navigate} from "react-router-dom";
import sunExposureValues from "../constants/sunExposureValues";

const nextPage = "/hemisphere";

class SunExposureWeekend extends React.Component {
    constructor(props) {
        super(props);
        this.nextPage = this.nextPage.bind(this);
        this.setWeekend = this.setWeekend.bind(this);
        this.state = {
            redirect: false,
        }

    }
    setWeekend (e) {
        this.props.onDataChange(e, 'sun_exposure_weekend');
        setTimeout(function() {this.nextPage()}.bind(this), 400);
    }
    nextPage () {
        this.setState({redirect: true,})
    }
    componentDidMount() {
        this.props.onDataChange('sun_exposure_weekend', 'page');
    }

    render() {
        const option = sunExposureValues;
        return (
            <div className={'text-center page pageSunWeekend'}>
                <h1>How much sun do you get each weekend (Sat-Sun) without sunscreen?</h1>
                <div className={'row btnRow'}>
                    <div className={'col'}>
                        <button type="button"
                                className={'btn-choice ' + (this.props.sun_exposure_weekend === option["1"]  ? 'active' : '')}
                                onClick={() => this.setWeekend(option["1"])}>{option["1"]}</button>
                    </div>
                    <div className={'col'}>
                        <button type="button"
                                className={'btn-choice ' + (this.props.sun_exposure_weekend === option["2"] ? 'active' : '')}
                                onClick={() => this.setWeekend(option["2"])}>{option["2"]}</button>
                    </div>
                    <div className={'col'}>
                        <button type="button"
                                className={'btn-choice ' + (this.props.sun_exposure_weekend === option["3"]  ? 'active' : '')}
                                onClick={() => this.setWeekend(option["3"])}>{option["3"]}</button>
                    </div>

                </div>
                {this.state.redirect && (
                    <Navigate to={nextPage} replace={true} />
                )}
            </div>
        )
    }
}

export default SunExposureWeekend;
