import React from "react";
import {Navigate} from "react-router-dom";

const nextPage = "/skin";

class Weight extends React.Component {
    constructor(props) {
        super(props);
        this.nextPage = this.nextPage.bind(this);
        this.setWeight = this.setWeight.bind(this);
        this.state = {
            weightValid: null,
            weightUnits: false,
            redirect: false,
        }
    }
    setWeight(e) {
        this.validateWeight(e.target.value);
        this.props.onDataChange(e.target.value, 'weight');
    }
    validateWeight(weight) {
        if (weight.length > 0) this.setState({weightUnits: true});
        else this.setState({weightUnits: false});

        if (/^((0|([1-9][0-9]*))(\.[0-9]+)?)$/.test(weight) && parseInt(weight) > 30 && parseInt(weight) <= 700) {
            this.setState({weightValid: true});
        } else {
            this.setState({weightValid: false});
        }
    }
    nextPage () {
        if (this.state.weightValid === true) this.setState({redirect: true,})
    }
    componentDidMount() {
        if (this.props.weight) this.validateWeight(this.props.weight);
        this.props.onDataChange('weight', 'page');
    }
    render() {
        return (
            <div className="page pageWeight text-center">
                <h1>What is your weight?</h1>
                <div className={'labeledPlaceholder'}>
                    <input className={'text-center' + (this.state.weightValid === false ? ' error' : '')} type={'text'}  placeholder={'Pounds'}
                           value={this.props.weight ? this.props.weight : ''}
                           onChange={this.setWeight}
                    />
                    <label className={'labeled' + (this.state.weightUnits ? ' active' : '')}>lb</label>
                </div>

                <br />
                <button onClick={this.nextPage} type="button" className={'btn-primary ' + (this.state.weightValid === true ? 'active' : '')}>Next -></button>
                {this.state.redirect && (
                    <Navigate to={nextPage} replace={true} />
                )}
            </div>
        )
    }
}

export default Weight;
