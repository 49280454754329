import React from 'react';
import logo from '../assets/images/logo-nav.png';
import {Link} from "react-router-dom";

class Header extends React.Component {

    exitFunction () {
        localStorage.removeItem('name');
        localStorage.removeItem('email');
    }
    render() {
        let page = this.props.page;
        let previousPage = false;

        let previousPageHeight = '/pregnancy';
        if (this.props.gender === 'male') previousPageHeight = '/gender';
        switch (page) {
            case 'conditions': previousPage = '/'; break;
            case 'personalInformation': previousPage = '/conditions'; break;
            case 'welcome': previousPage = '/personalInformation'; break;
            case 'age': previousPage = '/welcome'; break;
            case 'gender': previousPage = '/age'; break;
            case 'pregnancy': previousPage = '/gender'; break;
            case 'height': previousPage = previousPageHeight; break;
            case 'weight': previousPage = '/height'; break;
            case 'skin': previousPage = '/weight'; break;
            case 'lifestyle': previousPage = '/skin'; break;
            case 'sun_exposure_weekday': previousPage = '/lifestyle'; break;
            case 'sun_exposure_weekend': previousPage = '/sun_exposure_weekday'; break;
            case 'hemisphere': previousPage = '/sun_exposure_weekend'; break;
            case 'medical': previousPage = '/hemisphere'; break;
            case 'cancer': previousPage = '/medical'; break;
            case 'do_you_take': previousPage = '/cancer'; break;
            case 'thank_you': previousPage = '/do_you_take'; break;
            case 'needs': previousPage = '/thank_you'; break;

            case 'speak_with_physician': previousPage = '/conditions'; break;
            case 'speak_with_pediatrician': previousPage = '/age'; break;
            default: previousPage = '/';
        }
        return(
            <>
                <header className={'row'}>
                    <img className={'img-fluid'} src={logo} alt={'THE VITAMIN D DOCTOR'} />
                    <div className="col leftHeaderText">
                        {this.props.page !== 'start' ? <Link to={previousPage}><span className={'desktop'}>&#60; Previous</span><span className={'mobile'}>&#60;-</span></Link> : <span className={'desktop'}>Healthy Vitamin D Quiz</span>}
                    </div>
                    <div className="col text-right exitLink">
                        {this.props.page !== 'start' ? <Link onClick={this.exitFunction} to="/"><span className={'text-nowrap desktop'}>Exit Quiz X</span><span className={'mobile'}>X</span></Link> : ''}
                    </div>
                </header>
                <div id={'headerBorderBottom'}></div>
            </>
        )
    }
}
export default Header;
