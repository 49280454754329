const appSatePredefined = {
    page: false,
    name: 'Andrew',
    email: 'andrew.yastin@gmail.com',
    gender: 'male', //male | female
    age: '30+',
    pregnancy: 'No',
    feet: '6',
    inches: '1',
    weight: '190',
    skin: 'Light',
    sun_exposure_weekday: '<30 min', //<30 min | 30-60 min | >60 min
    sun_exposure_weekend: '<30 min',
    cancer: 'No',
    conditions: [],
    do_you_take: 'No',
    hemisphere: 'Northern',
}

export default appSatePredefined;
