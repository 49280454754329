const appSateEmpty = {
    page: '/',
    name: false,
    email: false,
    gender: false,
    age: false,
    pregnancy: false,
    feet: false,
    inches: false,
    weight: false,
    skin: false,
    sun_exposure_weekday: false,
    sun_exposure_weekend: false,
    cancer: false,
    conditions: [],
    do_you_take: false,
    hemisphere: false,
}

export default appSateEmpty;
