import React from "react";
import needsSunProductionSunExposure from "./constants/needsSunProductionSunExposure";

class Logic extends React.Component {
    static season(hemisphere) {
        let now = new Date();
        const seasonsNorthern = [
            'W', //Winter
            'W',
            'Sp', //Spring
            'Sp',
            'Sp',
            'Su', //Summer
            'Su',
            'Su',
            'F', //Fall
            'F',
            'F',
            'W',
        ];
        const seasonsSouthern = [
            'Su',
            'Su',
            'F',
            'F',
            'F',
            'W',
            'W',
            'W',
            'Sp',
            'Sp',
            'Sp',
            'Su',
        ];

        if (hemisphere === 'Northern') {
            return seasonsNorthern[now.getMonth()];
        } else {
            return seasonsSouthern[now.getMonth()];
        }
    }
    static skinColor(color) {
        if (!color) return {
            level: '?',
            score: '?',
        };
        const levels = {
            'Black': 'High',
            'Dark Brown': 'High',
            'Olive/Brown': 'Medium',
            'Medium': 'Medium',
            'Light': 'Low',
            'Very Light': 'Low',
        };
        const scores = {
            'High': 4,
            'Medium': 2,
            'Low': 0,
        };
        let level = levels[color];
        return {
            level: level,
            score: scores[level]
        };
    }
    static activity(outdoor) {
        const weekdaysScores = {
            '<30 min': 5,
            '30-60 min': 10,
            '>60 min': 15,
        };
        const weekendsScores = {
            '<30 min': 2,
            '30-60 min': 4,
            '>60 min': 6,
        };
        let sunTimeScore = weekdaysScores[outdoor.sun_exposure_weekday] + weekendsScores[outdoor.sun_exposure_weekend];
        let sunTimeLevel = 'Medium';
        if (sunTimeScore < 14) {
            sunTimeLevel = 'High';
        } else if (sunTimeScore > 20) {
            sunTimeLevel = 'Low';
        }

        return {
            total: {
                score: sunTimeScore,
                level: sunTimeLevel,
            },
            composite: sunTimeLevel,
        };
    }
    static weight(state) {
        const score = { 'High': 3, 'Medium': 2, 'Low': 0, };

        let height = (parseInt(state.feet ) * 12 + parseFloat(state.inches)) * 2.54;
        let weight = parseInt(state.weight) / 2.205;
        let bmi = Math.round(weight / height / height * 100*100*100)/100;

        let level = 'Medium';
        if (bmi < 25) {
            level = 'Low';
        } else if (bmi >= 30) {
            level = 'High';
        }

        return {
            bmi: bmi,
            level: level,
            score: score[level],
        }
    }
    static bmiSkinProductionComposite(data) {
        let productionLevel = Logic.skinColor(data.skin);
        let weightLevel = Logic.weight(data);

        if ('?' === productionLevel.level) {
            return {
                level: '?',
                score: '?',
                debug: '?',
            }
        }
        const scores = {
            'High': {
                'High': 5,
                'Medium': 4,
                'Low': 2,
            },
            'Medium': {
                'High': 4,
                'Medium': 3,
                'Low': 1,
            },
            'Low': {
                'High': 3,
                'Medium': 2,
                'Low': 0,
            },
        };
        let score = scores[productionLevel.level][weightLevel.level];
        let level = 'Medium';
        if (score < 2) {
            level = 'Low';
        } else if (score > 3) {
            level = 'High';
        }

        return {
            level: level,
            score: score,
        };
    }
    static needsNewModel2022 (state) {
        let weight = Logic.weight(state);
        let skinColor = Logic.skinColor(state.skin);
        let sunExposure = Logic.activity({sun_exposure_weekday: state.sun_exposure_weekday, sun_exposure_weekend: state.sun_exposure_weekend});
        let seasonValue = Logic.season(state.hemisphere);
        let needs = needsSunProductionSunExposure[skinColor.level][sunExposure.composite][seasonValue];

        if ('Medium' === weight.level) {
            needs = needs * 1.23;
        } else if ('High' === weight.level) {
            needs = needs * 2.26;
        }

        let composite = 'Medium';
        if (needs < 200) {
            composite = 'High';
        }
        if (needs > 200) {
            composite = 'Low';
        }

        return {
            needs: needs,
            composite: composite,
        }
    }
    static validateState (state) {
        if (!state.name || !state.email || !state.gender || !state.age || !state.feet || !state.inches || !state.weight || !state.skin || !state.sun_exposure_weekday || !state.sun_exposure_weekend || !state.cancer || !state.do_you_take || !state.hemisphere || !localStorage.getItem('name') || !localStorage.getItem('email'))
        {
            localStorage.removeItem('name');
            localStorage.removeItem('email');
            return false;
        }
        return true;
    }
    static additionalD_needsLb (state) {
        if (!this.validateState(state)) return false;
        let additionalNeeds = Logic.needsNewModel2022(state);

        let weightLb = parseFloat(state.weight);

        let additionalNeeds40 = (additionalNeeds.needs + weightLb * 11.5);
        if (additionalNeeds40 > 10000) additionalNeeds40 = 10000;

        let additionalNeeds45 = (additionalNeeds.needs + weightLb * 18);
        if (additionalNeeds45 > 10000) additionalNeeds45 = 10000;

        let additionalNeeds50 = (additionalNeeds.needs + weightLb * 25);
        if (additionalNeeds50 > 10000) additionalNeeds50 = 10000;
        let additionalNeeds55 = (additionalNeeds.needs + weightLb * 32.75);
        if (additionalNeeds55 > 10000) additionalNeeds55 = 10000;
        let additionalNeeds60 = (additionalNeeds.needs + weightLb * 41);
        if (additionalNeeds60 > 10000) additionalNeeds60 = 10000;

        return {
            additionalNeeds40: Math.round(additionalNeeds40),
            additionalNeeds45: Math.round(additionalNeeds45),
            additionalNeeds50: Math.round(additionalNeeds50),
            additionalNeeds55: Math.round(additionalNeeds55),
            additionalNeeds60: Math.round(additionalNeeds60),
            debugCalculations: {
                weight: weightLb,
                needs: additionalNeeds.needs,
            }
        }
    }
}

export default Logic;
