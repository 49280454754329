import React from "react";

class SpeakWithPediatrician extends React.Component {
    componentDidMount() {
        this.props.onDataChange('speak_with_pediatrician', 'page');
    }

    render() {
        return (
            <div className={'text-center page pagePhysician'}>
                <div className={'row'}>
                    <div className={'col'}>
                        <h1>It is recommended you speak with your pediatrician about Vitamin D.</h1>
                    </div>
                    <div className={'col'}>
                        <p>Our customized Vitamin D Nutrients dosing schedule is only available for adults at this time. </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default SpeakWithPediatrician;
