import React from "react";
import {Navigate} from "react-router-dom";

const nextPage = "/gender";
const nextPageBreak = "/speak_with_pediatrician";

class Age extends React.Component {
    constructor(props) {
        super(props);
        this.nextPage = this.nextPage.bind(this);
        this.state = {
            redirect: false,
        }
    }
    nextPage () {
        this.setState({redirect: true,})
    }
    setAge(e) {
        this.props.onDataChange(e, 'age');
        setTimeout(function() {this.nextPage()}.bind(this), 400);
    }
    componentDidMount() {
        this.props.onDataChange('age', 'page');
    }

    render() {
        let next = nextPage;
        if (this.props.age === '<18') next = nextPageBreak;
        return (
            <div className={'text-center page pageAge'}>
                <h1>What is your age range?</h1>
                <div className={'row'}>
                    <div className={'col'}>
                        <button type="button" className={'btn-choice btn-choice-big ' + (this.props.age === '<18'  ? 'active' : '')} onClick={() => this.setAge('<18')}>&#60;18</button>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        <button type="button" className={'btn-choice btn-choice-big ' + (this.props.age === '18-30'  ? 'active' : '')} onClick={() => this.setAge('18-30')}>18-30</button>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        <button type="button" className={'btn-choice btn-choice-big ' + (this.props.age === '30+'  ? 'active' : '')} onClick={() => this.setAge('30+')}>30+</button>
                    </div>
                </div>
                {this.state.redirect && (
                    <Navigate to={next} replace={true} />
                )}
            </div>
        )
    }
}

export default Age;
