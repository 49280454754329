import React from "react";
import image from '../../assets/images/start-page-main-image.png';
import {Link, Navigate} from "react-router-dom";

class Start extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            pathToRedirect: false,
        }

    }
    componentDidMount() {
        this.props.onDataChange('start', 'page');
        this.props.onDataChange('/', 'previousPage');
        if (this.props.redirectStartPageTo) this.setState({
            redirect: true,
            pathToRedirect: this.props.redirectStartPageTo,
        });
    }

    render() {
        return (
            <div className={'page pageStart'}>
                <div className={'row'}>
                    <div className={'col left'}>
                        <img className={''} src={image} alt={'Vitamin D Quiz'} />
                    </div>
                    <div className={'col text-center right'}>
                        <h1>Healthy Vitamin D Quiz</h1>
                        <p>Learn how much Vitamin D you need.<br />It’s different for everyone.<br />Get your Healthy Vitamin D Levels Plan so you can take charge of your healthy naturally.</p>
                        <div className={'spacer'}></div>
                        <Link to="conditions"><span className={'btn-primary btnStart active'}>Start -></span></Link>
                    </div>
                    {this.state.redirect && (
                        <Navigate to={this.props.redirectStartPageTo} replace={true} />
                    )}
                </div>
            </div>
        );
    }
}
export default Start;
