import './App.scss';
import './AppResponsive.scss';
import VitaminD from "./VitaminD";
import {BrowserRouter} from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <VitaminD />
    </BrowserRouter>
  );
}

export default App;
