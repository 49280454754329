import React from "react";
import {Navigate} from "react-router-dom";

const nextPage = "/sun_exposure_weekday";

class Lifestyle extends React.Component {
    constructor(props) {
        super(props);
        this.nextPage = this.nextPage.bind(this);
        this.state = {
            redirect: false,
        }

    }
    nextPage () {
        this.setState({redirect: true,})
    }
    componentDidMount() {
        this.props.onDataChange('lifestyle', 'page');
        setTimeout(function() {this.nextPage()}.bind(this), this.props.timeoutDelay);
    }

    render() {
        return (
            <div className={'text-center page pageTransition'}>
                <ul>
                    <li><span>1/1</span><h1>Physical</h1></li>
                    <li className={'active'}><span>2/3</span><h1>Lifestyle</h1></li>
                    <li><span>3/3</span><h1>Medical</h1></li>
                </ul>
                {this.state.redirect && (
                    <Navigate to={nextPage} replace={true} />
                )}
            </div>
        )
    }
}

export default Lifestyle;
