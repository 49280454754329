import React from "react";
import {Link, Route, Routes} from "react-router-dom";
import Header from "./components/Header";
import ProgressBar from "./components/ProgressBar";
import Start from "./components/pages/Start";
import PersonalInformation from "./components/pages/PersonalInformation";
import Welcome from "./components/pages/Welcome";
import Gender from "./components/pages/Gender";
import Age from "./components/pages/Age";
import Height from "./components/pages/Height";
import Pregnancy from "./components/pages/Pregnancy";
import Weight from "./components/pages/Weigh";
import Skin from "./components/pages/Skin";
import Lifestyle from "./components/pages/Lifestyle";
import SunExposureWeekday from "./components/pages/SunExposureWeekday";
import Needs from "./components/pages/Needs";
import ThankYou from "./components/pages/ThankYou";
import DoYouTake from "./components/pages/DoYouTake";
import Conditions from "./components/pages/Conditions";
import Cancer from "./components/pages/Cancer";
import Medical from "./components/pages/Medical";
import SunExposureWeekend from "./components/pages/SunExposureWeekend";
import appSateEmpty from "./components/constants/appSateEmpty";
import appSatePredefined from "./components/constants/appSatePredefined";
import SpeakWithPhysician from "./components/pages/SpeakWithPhysician";
import Hemisphere from "./components/pages/Hemisphere";
import SpeakWithPediatrician from "./components/pages/SpeakWithPediatrician";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const timeoutDelay = 2000;

/*dev*/
const predefined = appSatePredefined;
const defaultValues = appSateEmpty;

//const redirectStartPageTo = '/thank_you';
const redirectStartPageTo = false;
const usePredefinedData = false;
const debug = false;
const quickNav = false;
/*dev*/

class VitaminD extends React.Component {
    constructor(props) {
        super(props);
        this.handleDataChange = this.handleDataChange.bind(this);
        if (usePredefinedData) {
            this.state = predefined;
        } else {
            this.state = defaultValues;
        }
    }
    handleDataChange (data, type) {
        this.setState({
            [type]: data
        });
    }
    render() {
        const pages = [
            'start',
            'conditions',
            'personalInformation',
            'welcome',
            'age',
            'gender',
            'pregnancy',
            'height',
            'weight',
            'skin',
            'lifestyle',
            'sun_exposure_weekday',
            'sun_exposure_weekend',
            'hemisphere',
            'medical',
            'cancer',
            'do_you_take',
            'thank_you',
            'needs',
        ]

        let devNav = pages.map((item, key) =>
            <span key={key}>
                &nbsp;| <Link className={this.state.page === item ? 'active' : ''} to={item === 'start' ? '/' : item} >{item}</Link>
            </span>
        );
        return (
            <div className="App container-fluid">

                <Header page={this.state.page} gender={this.state.gender} />
                <ProgressBar page={this.state.page} />

                <TransitionGroup component={null}>
                    <CSSTransition key={this.state.page} classNames="fade" timeout={300}>
                            <Routes>
                            <Route path={"/"} element={<Start onDataChange={this.handleDataChange} redirectStartPageTo={redirectStartPageTo} />}></Route>
                            <Route path={"/conditions"} element={<Conditions onDataChange={this.handleDataChange} conditions={this.state.conditions} />}></Route>
                            <Route path={"/personalInformation"} element={<PersonalInformation name={this.state.name} email={this.state.email} onDataChange={this.handleDataChange} />}></Route>
                            <Route path={"/welcome"} element={<Welcome onDataChange={this.handleDataChange} name={this.state.name} timeoutDelay={timeoutDelay} />}></Route>
                            <Route path={"/age"} element={<Age onDataChange={this.handleDataChange} age={this.state.age} />}></Route>
                            <Route path={"/gender"} element={<Gender onDataChange={this.handleDataChange} gender={this.state.gender} />}></Route>
                            <Route path={"/pregnancy"} element={<Pregnancy onDataChange={this.handleDataChange} pregnancy={this.state.pregnancy} gender={this.state.gender} />}></Route>
                            <Route path={"/height"} element={<Height onDataChange={this.handleDataChange} feet={this.state.feet} inches={this.state.inches} />}></Route>
                            <Route path={"/weight"} element={<Weight onDataChange={this.handleDataChange} weight={this.state.weight} />}></Route>
                            <Route path={"/skin"} element={<Skin onDataChange={this.handleDataChange} skin={this.state.skin} />}></Route>
                            <Route path={"/lifestyle"} element={<Lifestyle onDataChange={this.handleDataChange} lifestyle={this.state.lifestyle} timeoutDelay={timeoutDelay} />}></Route>
                            <Route path={"/sun_exposure_weekday"} element={<SunExposureWeekday onDataChange={this.handleDataChange} sun_exposure_weekday={this.state.sun_exposure_weekday} />}></Route>
                            <Route path={"/sun_exposure_weekend"} element={<SunExposureWeekend onDataChange={this.handleDataChange} sun_exposure_weekend={this.state.sun_exposure_weekend} />}></Route>
                            <Route path={"/hemisphere"} element={<Hemisphere onDataChange={this.handleDataChange} hemisphere={this.state.hemisphere} />}></Route>
                            <Route path={"/medical"} element={<Medical onDataChange={this.handleDataChange} medical={this.state.medical} timeoutDelay={timeoutDelay} />}></Route>
                            <Route path={"/cancer"} element={<Cancer onDataChange={this.handleDataChange} cancer={this.state.cancer} />}></Route>
                            <Route path={"/do_you_take"} element={<DoYouTake onDataChange={this.handleDataChange} do_you_take={this.state.do_you_take} />}></Route>
                            <Route path={"/thank_you"} element={<ThankYou onDataChange={this.handleDataChange} state={this.state} />}></Route>
                            <Route path={"/needs"} element={<Needs onDataChange={this.handleDataChange} state={this.state} />}></Route>

                            <Route path={"/speak_with_physician"} element={<SpeakWithPhysician onDataChange={this.handleDataChange} />}></Route>
                            <Route path={"/speak_with_pediatrician"} element={<SpeakWithPediatrician onDataChange={this.handleDataChange} />}></Route>
                        </Routes>
                    </CSSTransition>
                </TransitionGroup>
                {quickNav ?
                    <div className={'devNav'}>
                        {devNav}
                    </div> :
                    ''
                }
                {debug ?
                <div className={'devState'}>
                    <span >{this.state.name} {this.state.email}</span>
                    &nbsp;| <span>{this.state.gender} {this.state.age}</span>
                    &nbsp;| <span>pregnancy: {this.state.pregnancy}</span>
                    &nbsp;| <span>{this.state.feet}ft {this.state.inches}in {this.state.weight}lb</span>
                    &nbsp;| <span>Skin: {this.state.skin}</span>
                    &nbsp;| <span>SE: {this.state.sun_exposure_weekday}/{this.state.sun_exposure_weekend}</span>
                    &nbsp;| <span>Cancer: {this.state.cancer} / Coumadin: {this.state.do_you_take}</span>
                    &nbsp;| <span>{this.state.hemisphere}</span>
                    &nbsp;| <span>{this.state.page}</span>
                    &nbsp;| <span>LS: {localStorage.getItem('name')}</span>
                </div> : ''}
            </div>
        )
    }
}

export default VitaminD;
