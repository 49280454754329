import React from "react";
import {Navigate} from "react-router-dom";

let nextPage = "/age";

class Welcome extends React.Component {
    constructor(props) {
        super(props);
        this.nextPage = this.nextPage.bind(this);
        this.state = {
            redirect: false,
        }

    }
    nextPage () {
        this.setState({redirect: true,})
    }
    componentDidMount() {
        this.props.onDataChange('welcome', 'page');
        let nameLS = localStorage.getItem('name');
        if (nameLS && nameLS !== '') this.props.onDataChange(nameLS, 'name');

        let emailLS = localStorage.getItem('email');
        if (emailLS && emailLS !== '') this.props.onDataChange(emailLS, 'email');

        if (!nameLS && !emailLS) {nextPage = "/personalInformation"; this.setState({redirect: true,});}
        else {
            this.props.onDataChange('welcome', 'page');
            setTimeout(function() {this.nextPage()}.bind(this), this.props.timeoutDelay);
        }
    }

    render() {
        //let nextPage = "/age";
        return (
            <div className={'text-center page pageWelcome'}>
                <h1>Welcome, {this.props.name}!</h1>
                <p>This will take less than 2 minutes.</p>
                {this.state.redirect && (
                    <Navigate to={nextPage} replace={true} />
                )}
            </div>
        )
    }
}

export default Welcome;
