const needsSunProductionSunExposure = {
    High: {
        High: {
            F: 2500,
            W: 3100,
            Sp: 2900,
            Su: 2100,
        },
        Medium: {
            F: 1875,
            W: 2675,
            Sp: 2150,
            Su: 1550,
        },
        Low: {
            F: 1250,
            W: 2250,
            Sp: 1400,
            Su: 1000,
        },
    },
    Medium: {
        High: {
            F: 2075,
            W: 2825,
            Sp: 2575,
            Su: 1550,
        },
        Medium: {
            F: 1350,
            W: 2300,
            Sp: 1650,
            Su: 1025,
        },
        Low: {
            F: 625,
            W: 1775,
            Sp: 725,
            Su: 500,
        },
    },
    Low: {
        High: {
            F: 1650,
            W: 2550,
            Sp: 2250,
            Su: 1000,
        },
        Medium: {
            F: 825,
            W: 1925,
            Sp: 1150,
            Su: 500,
        },
        Low: {
            F: 0,
            W: 1300,
            Sp: 50,
            Su: 0,
        },
    },
}

export default needsSunProductionSunExposure;
