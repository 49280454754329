import React from "react";
import {Navigate} from "react-router-dom";

const nextPage = "/thank_you";

class DoYouTake extends React.Component {
    constructor(props) {
        super(props);
        this.nextPage = this.nextPage.bind(this);
        this.setData = this.setData.bind(this);
        this.state = {
            redirect: false,
        }

    }
    setData(e) {
        this.props.onDataChange(e, 'do_you_take');
        setTimeout(function() {this.nextPage()}.bind(this), 400);
    }
    nextPage () {
        this.setState({redirect: true,})
    }
    componentDidMount() {
        this.props.onDataChange('do_you_take', 'page');
    }

    render() {
        return (
            <div className={'text-center page pageDoYouTake'}>
                <h1>Do you you take coumadin (warfarin)?</h1>
                <div className={'row'}>
                    <div className={'col'}>
                        <button type="button" className={'btn-choice btn-choice-big ' + (this.props.do_you_take === 'Yes'  ? 'active' : '')} onClick={() => this.setData('Yes')}>Yes</button>
                    </div>
                </div><br />
                <div className={'row'}>
                    <div className={'col'}>
                        <button type="button" className={'btn-choice btn-choice-big ' + (this.props.do_you_take === 'No'  ? 'active' : '')} onClick={() => this.setData('No')}>No</button>
                    </div>
                </div>
                {this.state.redirect && (
                    <Navigate to={nextPage} replace={true} />
                )}
            </div>
        )
    }
}

export default DoYouTake;
