import React from "react";
import {Navigate} from "react-router-dom";

const nextPage = "/lifestyle";

class Skin extends React.Component {
    constructor(props) {
        super(props);
        this.setSkinColor = this.setSkinColor.bind(this);
        this.state = {
            redirect: false,
        }

    }
    setSkinColor (e) {
        this.props.onDataChange(e, 'skin');
        setTimeout(function() {this.nextPage()}.bind(this), 400);
    }
    nextPage () {
        this.setState({redirect: true,})
    }
    componentDidMount() {
        this.props.onDataChange('skin', 'page');
    }

    render() {
        return (
            <div className="text-center page pageSkinColor">
                <h1>What is your skin color?</h1>
                <div className={'inputs'}>
                    <div className={'row'}>
                        <div className={'col'}>
                            <button type="button"
                                    className={(this.props.skin === 'Very Light' ? 'active' : '') + ' btn-choice button1'}
                                    onClick={() => this.setSkinColor('Very Light')}>Very Light</button>
                        </div>
                        <div className={'col'}>
                            <button type="button"
                                    className={(this.props.skin === 'Light' ? 'active' : '') + ' btn-choice button2'}
                                    onClick={() => this.setSkinColor('Light')}>Light</button>
                        </div>
                        <div className={'col'}>
                            <button type="button"
                                    className={(this.props.skin === 'Medium' ? 'active' : '') + ' btn-choice button3'}
                                    onClick={() => this.setSkinColor('Medium')}>Medium</button>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col'}>
                            <button type="button"
                                    className={(this.props.skin === 'Olive/Brown' ? 'active' : '') + ' btn-choice button4'}
                                    onClick={() => this.setSkinColor('Olive/Brown')}>Olive/Brown</button>
                        </div>
                        <div className={'col'}>
                            <button type="button"
                                    className={(this.props.skin === 'Dark Brown' ? 'active' : '') + ' btn-choice button5'}
                                    onClick={() => this.setSkinColor('Dark Brown')}>Dark Brown</button>
                        </div>
                        <div className={'col'}>
                            <button type="button"
                                    className={(this.props.skin === 'Black' ? 'active' : '') + ' btn-choice button6'}
                                    onClick={() => this.setSkinColor('Black')}>Black</button>
                        </div>
                    </div>
                </div>
                {this.state.redirect && (
                    <Navigate to={nextPage} replace={true} />
                )}
            </div>
        )
    }
}

export default Skin;
