import React from "react";
import {Navigate} from "react-router-dom";

const nextPage = "/medical";

class Hemisphere extends React.Component {
    constructor(props) {
        super(props);
        this.nextPage = this.nextPage.bind(this);
        this.state = {
            redirect: false,
        }
    }
    nextPage () {
        this.setState({redirect: true,})
    }
    setHemisphere(e) {
        this.props.onDataChange(e, 'hemisphere');
        setTimeout(function() {this.nextPage()}.bind(this), 400);
    }
    componentDidMount() {
        this.props.onDataChange('hemisphere', 'page');
    }

    render() {
        return (
            <div className={'text-center page pagePregnancy'}>
                <h1>What hemisphere are you located in?</h1>
                <div className={'row'}>
                    <div className={'col'}>
                        <button type="button" className={'btn-choice btn-choice-big ' + (this.props.hemisphere === 'Northern'  ? 'active' : '')} onClick={() => this.setHemisphere('Northern')}>Northern</button>
                    </div>
                </div><br />
                <div className={'row'}>
                    <div className={'col'}>
                        <button type="button" className={'btn-choice btn-choice-big ' + (this.props.hemisphere === 'Southern'  ? 'active' : '')} onClick={() => this.setHemisphere('Southern')}>Southern</button>
                    </div>
                </div>
                {this.state.redirect && (
                    <Navigate to={nextPage} replace={true} />
                )}
            </div>
        )
    }
}

export default Hemisphere;
