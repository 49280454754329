import React from "react";
import {Navigate} from "react-router-dom";

const nextPage = "/pregnancy";

class Gender extends React.Component {
    constructor(props) {
        super(props);
        this.nextPage = this.nextPage.bind(this);
        this.state = {
            redirect: false,
        }

    }
    nextPage () {
        this.setState({redirect: true,})
    }
    setGender(e) {
        this.props.onDataChange(e, 'gender');
        setTimeout(function() {this.nextPage()}.bind(this), 400);
    }
    componentDidMount() {
        this.props.onDataChange('gender', 'page');
    }

    render() {
        return (
            <div className={'text-center page pageGender'}>
                <h1>What is your gender?</h1>
                <div className={'inputs'}>
                    <div className={'row'}>
                        <div className={'col'}>
                            <button type="button" className={'btn-choice btn-choice-big ' + (this.props.gender === 'male'  ? 'active' : '')} onClick={() => this.setGender('male')}>Male</button>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col'}>
                            <button type="button" className={'btn-choice btn-choice-big ' + (this.props.gender === 'female'  ? 'active' : '')} onClick={() => this.setGender('female')}>Female</button>
                        </div>
                    </div>
                </div>
                {this.state.redirect && (
                    <Navigate to={nextPage} replace={true} />
                )}
            </div>
        )
    }
}

export default Gender;
