import React from "react";

class PersonalInformation extends React.Component {
    constructor(props) {
        super(props);
        this.submitForm = this.submitForm.bind(this);
        this.handleName = this.handleName.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.setName = this.setName.bind(this);
        this.setEmail = this.setEmail.bind(this);
        this.state = {
            nameValid: null,
            emailValid: null,
            redirect: false,
        }
    }
    submitForm (e) {
        e.preventDefault();
        if (this.state.nameValid === true && this.state.emailValid === true) {
            localStorage.setItem('name', e.target.elements.inf_field_FirstName.value);
            localStorage.setItem('email', e.target.elements.inf_field_Email.value);
            e.currentTarget.submit();
        }
    }
    handleName (e) {
        this.setName(e.target.value);
    }
    setName(value, dataChanged = true) {
        this.validateName(value);
        this.props.onDataChange(value, 'name');
    }
    validateName (name) {
        if (name.length > 1) this.setState({ nameValid: true });
        else this.setState({ nameValid: false });
    }
    handleEmail (e) {
        this.setEmail(e.target.value);
    }
    setEmail(value, dataChanged = true) {
        this.validateEmail(value);
        this.props.onDataChange(value, 'email');
    }
    validateEmail(email) {
        if (/^[a-zA-Z0-9._-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(email)) this.setState({ emailValid: true });
        else this.setState({ emailValid: false });
    }
    componentDidMount() {
        if (this.props.name) this.validateName(this.props.name);
        if (this.props.email) this.validateEmail(this.props.email);
        this.props.onDataChange('personalInformation', 'page');

        let nameLS = localStorage.getItem('name');
        if (nameLS && nameLS !== '') this.setName(nameLS, false);

        let emailLS = localStorage.getItem('email');
        if (emailLS && emailLS !== '') this.setEmail(emailLS, false)
    }
    render() {

        let inf_form_xid = 'fa417e574e1b42c4207a83a1d6762e1a';
        if (window.location.hostname === 'localhost') {
            inf_form_xid = '4e129e40ee4783a1267c29b456686377';
        }

        return (
            <div className="text-center pagePersonalInformation page">
                <form acceptCharset="UTF-8" onSubmit={this.submitForm}
                      action={'https://themillmanclinic.infusionsoft.com/app/form/process/' + inf_form_xid}
                      className="infusion-form" id={'inf_form_' + inf_form_xid} method="POST">
                    <input name="inf_form_xid" type="hidden" value={inf_form_xid} />
                    <input name="inf_form_name" type="hidden" value="Web Form submitted" />
                    <input name="infusionsoft_version" type="hidden" value="1.70.0.529336" />
                    <p>Before we get started we need a <br />little more information about you.</p>
                    <div className={'personalInputs'}>
                        <div className={'row'}>
                            <div className={'col'}>
                                <label htmlFor={'firstName'} className={''}>What’s your name?</label>
                                <input name="inf_field_FirstName" id={'firstName'} className={'text-center' + (this.state.nameValid === false ? ' error' : '')} type={'text'}  placeholder={'First Name'}
                                       value={this.props.name ? this.props.name : ''}
                                       onChange={this.handleName}
                                />
                            </div>
                            <div className={'col'}>
                                <label htmlFor={'email'} className={''}>What’s your email?</label>
                                <input name="inf_field_Email" id={'email'} className={'text-center' + (this.state.emailValid === false ? ' error' : '')} type={'text'}  placeholder={'Email address'}
                                       value={this.props.email ? this.props.email : ''}
                                       onChange={this.handleEmail}
                                />
                            </div>
                        </div>
                    </div>
                    <button type={'submit'} className={'btn-primary ' + (this.state.nameValid === true && this.state.emailValid === true ? 'active' : '')}>Next -></button>
                </form>
            </div>
        );
    }
}
export default PersonalInformation;
