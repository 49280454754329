import React from "react";

class ProgressBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        let page = this.props.page;
        let progressBar = false; let physical = 0; let lifestyle = 0; let medical = 0;
        switch (page) {
            case 'welcome':
                progressBar = true; physical = 0; lifestyle = 0; medical = 0;
                break;
            case 'conditions': progressBar = false; physical = 0; lifestyle = 0; medical = 0; break;
            case 'gender': progressBar = true; physical = 15; lifestyle = 0; medical = 0; break;
            case 'age': progressBar = true; physical = 30; lifestyle = 0; medical = 0; break;
            case 'pregnancy': progressBar = true; physical = 45; lifestyle = 0; medical = 0; break;
            case 'height': progressBar = true; physical = 65; lifestyle = 0; medical = 0; break;
            case 'weight': progressBar = true; physical = 85; lifestyle = 0; medical = 0; break;
            case 'skin': progressBar = true; physical = 90; lifestyle = 0; medical = 0; break;
            case 'lifestyle': progressBar = true; physical = 100; lifestyle = 0; medical = 0; break;
            case 'sun_exposure_weekday': progressBar = true; physical = 100; lifestyle = 33; medical = 0; break;
            case 'sun_exposure_weekend': progressBar = true; physical = 100; lifestyle = 66; medical = 0; break;
            case 'hemisphere': progressBar = true; physical = 100; lifestyle = 100; medical = 0; break;
            case 'medical': progressBar = true; physical = 100; lifestyle = 100; medical = 0; break;
            case 'cancer': progressBar = true; physical = 100; lifestyle = 100; medical = 20; break;

            case 'do_you_take': progressBar = true; physical = 100; lifestyle = 100; medical = 100; break;
            default:
                progressBar = false; physical = 0; lifestyle = 0; medical = 0;
        }
        physical = physical + '%'; lifestyle = lifestyle + '%'; medical = medical + '%';
        return (
            <>
                {progressBar ?
                <div id={'progressBar'} className={'row'}>
                    <div className={'col physical'}>
                        <div className={'barWrapper'}><div className={'bar'} style={{width: physical}}></div></div>
                        <label>Physical</label>
                    </div>
                    <div className={'col lifestyle'}>
                        <div className={'barWrapper'}><div className={'bar'} style={{width: lifestyle}}></div></div>
                        <label>Lifestyle</label>
                    </div>
                    <div className={'col medical'}>
                        <div className={'barWrapper'}><div className={'bar'} style={{width: medical}}></div></div>
                        <label>Medical</label>
                    </div>
                </div>
                : ''}
            </>
        )
    }
}
export default ProgressBar;
