import React from "react";
import {Navigate} from "react-router-dom";

const nextPage = "/personalInformation";
const nextPageBreak = "/speak_with_physician";

class Conditions extends React.Component {
    constructor(props) {
        super(props);
        this.nextPage = this.nextPage.bind(this);
        this.setConditions = this.setConditions.bind(this);
        this.state = {
            redirect: false,
            conditions: [],
        }
    }
    setConditions (e) {
        let conditions = this.state.conditions;
        let newConditions = [];

        if (conditions.includes(e)) {
            conditions.forEach((r) => {
                if (r !== e) newConditions.push(r);
            })
            this.setState({conditions: newConditions});
            this.props.onDataChange(newConditions, 'conditions');
            return;
        } else {
            conditions.push(e);
        }
        this.setState({conditions: conditions});
        this.props.onDataChange(conditions, 'conditions');
    }
    nextPage () {
        this.setState({redirect: true,})
    }
    componentDidMount() {
        this.setState({conditions:this.props.conditions});
        this.props.onDataChange('conditions', 'page');
    }

    render() {
        let option1 = 'High Blood Calcium';
        let option2 = 'Hyper Parathyroid';
        let option3 = 'Lymphoma';
        let option4 = 'Sarcoidosis';
        let option5 = 'Active TB Infection';
        let option6 = 'Chronic Lymphocytic Leukemia';
        let next = nextPage;
        if (this.state.conditions.length > 0) next = nextPageBreak;
        return (
            <div className="text-center pageConditions page">
                <div className="">
                    <h1>Do you have any of these conditions?</h1>
                    <p className={'description'}>Select all that apply</p>
                    <div className={'rowsGroup'}>
                        <div className={'row btnRow'}>
                            <div className={'col'}>
                                <button type="button"
                                        className={'btn-choice ' + (this.state.conditions.includes(option1)  ? 'active' : '')}
                                        onClick={() => this.setConditions(option1)}>{option1}</button>
                            </div>
                            <div className={'col'}>
                                <button type="button"
                                        className={'btn-choice ' + (this.state.conditions.includes(option2)  ? 'active' : '')}
                                        onClick={() => this.setConditions(option2)}>{option2}</button>
                            </div>
                            <div className={'col'}>
                                <button type="button"
                                        className={'btn-choice ' + (this.state.conditions.includes(option3)  ? 'active' : '')}
                                        onClick={() => this.setConditions(option3)}>{option3}</button>
                            </div>
                        </div>
                        <div className={'row btnRow'}>
                            <div className={'col'}>
                                <button type="button"
                                        className={'btn-choice ' + (this.state.conditions.includes(option4)  ? 'active' : '')}
                                        onClick={() => this.setConditions(option4)}>{option4}</button>
                            </div>
                            <div className={'col'}>
                                <button type="button"
                                        className={'btn-choice ' + (this.state.conditions.includes(option5)  ? 'active' : '')}
                                        onClick={() => this.setConditions(option5)}>{option5}</button>
                            </div>
                            <div className={'col'}>
                                <button type="button"
                                        className={'btn-choice ' + (this.state.conditions.includes(option6)  ? 'active' : '')}
                                        onClick={() => this.setConditions(option6)}>{option6}</button>
                            </div>
                        </div>
                    </div>

                    <button onClick={this.nextPage} type="button" className={'btn btn-lg btn-primary active'}>Next -></button>
                    {this.state.redirect && (
                        <Navigate to={next} replace={true} />
                    )}
                </div>
            </div>
        )
    }
}

export default Conditions;
