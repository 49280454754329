import React from "react";
import {Navigate} from "react-router-dom";
import sunExposureValues from "../constants/sunExposureValues";

const nextPage = "/sun_exposure_weekend";

class SunExposureWeekday extends React.Component {
    constructor(props) {
        super(props);
        this.nextPage = this.nextPage.bind(this);
        this.setWeekday = this.setWeekday.bind(this);
        this.state = {
            redirect: false,
        }

    }
    setWeekday (e) {
        this.props.onDataChange(e, 'sun_exposure_weekday');
        setTimeout(function() {this.nextPage()}.bind(this), 400);
    }
    nextPage () {
        this.setState({redirect: true,})
    }
    componentDidMount() {
        this.props.onDataChange('sun_exposure_weekday', 'page');
    }
    render() {
        const option = sunExposureValues;
        return (
            <div className={'text-center page pageSunWeekday'}>
                <h1>How much sun do you get each weekday <span className={'whitespace-nowrap'}>(M-F)</span> without sunscreen?</h1>
                <div className={'row btnRow'}>
                    <div className={'col'}>
                        <button type="button"
                                className={'btn-choice ' + (this.props.sun_exposure_weekday === option["1"]  ? 'active' : '')}
                                onClick={() => this.setWeekday(option["1"])}>{option["1"]}</button>
                    </div>
                    <div className={'col'}>
                        <button type="button"
                                className={'btn-choice ' + (this.props.sun_exposure_weekday === option["2"] ? 'active' : '')}
                                onClick={() => this.setWeekday(option["2"])}>{option["2"]}</button>
                    </div>
                    <div className={'col'}>
                        <button type="button"
                                className={'btn-choice ' + (this.props.sun_exposure_weekday === option["3"]  ? 'active' : '')}
                                onClick={() => this.setWeekday(option["3"])}>{option["3"]}</button>
                    </div>

                </div>
                {this.state.redirect && (
                    <Navigate to={nextPage} replace={true} />
                )}
            </div>
        )
    }
}

export default SunExposureWeekday;
