import React from "react";
import {Navigate} from "react-router-dom";
import Logic from "../Logic";
import imageD3 from '../../assets/images/artboard-D3.gif';
import imageK2 from '../../assets/images/artboard-K2.gif';
import imageMg from '../../assets/images/artboard-Mg.gif';
import imageProductD2K3  from '../../assets/images/products/actionD3K2.png';
import imageProductD2  from '../../assets/images/products/actionD3.png';
import imageProductMg from  '../../assets/images/products/actionMagnesium.png';

class Needs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectEmptyToStart: false,
            vitaminDNeeds: false,
            vitaminKNeeds: false,
            needsMagnesium: false,
            vitaminDDrops: false,
            isNextAllowed: true,
        }
    }
    componentDidMount() {
        this.props.onDataChange('needs', 'page');
        let additionalNeedsLb = Logic.additionalD_needsLb(this.props.state);
        if (additionalNeedsLb) {
            let vitaminDNeeds = 0;
            if (this.props.state.cancer === 'Yes') vitaminDNeeds = additionalNeedsLb.additionalNeeds60;
            else vitaminDNeeds = additionalNeedsLb.additionalNeeds45;

            let vitaminKNeeds = Math.round(vitaminDNeeds / 1000) * 10;
            this.setState({
                vitaminDNeeds: vitaminDNeeds,
                vitaminKNeeds: vitaminKNeeds,
                vitaminDDrops: Math.round(vitaminDNeeds / 1000),
                needsMagnesium: 235,
            })
        } else {
            this.setState({redirectEmptyToStart: true,})
        }
    }
    render() {
        return (
            this.state.redirectEmptyToStart ? <Navigate to={'/'} replace={true} /> :
            <div className={'text-center page pageNeeds'}>
                <div className={'row'}>
                    <div className={'col left'}>
                        <h1>Here are your personalized <br className={'desktop'} />Vitamin D Nutrient Needs.</h1>
                        <div className={'needsColors'}>
                            <div className={'needsColor d3'}>
                                <img alt={'D3'} src={imageD3} />
                                <label>{ (this.state.vitaminDNeeds).toLocaleString('en-US') } IU</label>
                            </div>
                            {this.props.state.do_you_take === 'Yes' ? '' :
                            <div className={'needsColor k2'}>
                                <img alt={'D3'} src={imageK2} />
                                <label>{ this.state.vitaminKNeeds } MCG</label>
                            </div>
                            }
                            <div className={'needsColor mg'}>
                                <img alt={'D3'} src={imageMg} />
                                <label>{this.state.needsMagnesium} MG</label>
                            </div>
                        </div>
                    </div>
                    <div className={'col right'}>
                        <h2>How to be Sure You’re Taking the Correct Dose</h2>
                        <div className={'products'}>
                            {this.props.state.do_you_take === 'Yes' ?
                            <div className={'product'}>
                                <div className={'column pImage'}><img src={imageProductD2} alt={'D3 Liquid'}/></div>
                                <div className="column pDescription">
                                    <span className="productTitle">D3 Liquid</span>
                                    <span className="productTitleAmount"><strong>{this.state.vitaminDDrops} Drops per Day</strong><br />(1000 IU D3 per drop)</span>
                                    <p className="actionSupplementsProductDescription">This high potency liquid D3
                                        pairs perfectly with your personalized Vitamin D needs so you can easily take your precise
                                        dose. This Vitamin D3 is in MCT oil so it’s absorption is unparalleled. This
                                        ultra pure formulation has no fillers and is non GMO.</p>
                                </div>
                            </div>
                            :
                            <div className={'product'}>
                                <div className={'column pImage'}><img src={imageProductD2K3} alt={'D3K2 Liquid'}/></div>
                                <div className="column pDescription">
                                    <span className="productTitle">D3K2 Liquid</span>
                                    <span className="productTitleAmount"><strong>{this.state.vitaminDDrops} Drops per Day</strong><br />(1000 IU D3/10 mcg K2 per drop)</span>
                                    <p className="actionSupplementsProductDescription">This high potency liquid D3/K2
                                        pairs perfectly with your personalized Vitamin D needs so you can easily take your precise
                                        dose. This Vitamin D3/K2 is in MCT oil so it’s absorption is unparalleled. This
                                        ultra pure formulation has no fillers and is non GMO.</p>
                                </div>
                            </div>
                            }
                            <div className={'product'}>
                                <div className={'column pImage'}><img src={imageProductMg} alt={'Product Mg'}/></div>
                                <div className="column pDescription">
                                    <span className="productTitle">TriActive Magnesium</span>
                                    <span className="productTitleAmount"><strong>1 Capsule Twice Daily</strong><br/>(117.5 mg per capsule)</span>
                                    <p className="actionSupplementsProductDescription">TriActive Magnesium contains three types of magnesium which give you three times the ability to absorb, utilize and restore your body stores of Magnesium. This TriActive formula maximizes Magesium’s benefits, while minimizing the side effects of other forms, which can cause gas, bloating, diarrhea, and/or constipation.</p>
                                </div>
                            </div>
                        </div>
                        <div className={'text-center'}>
                            <a href={'https://themillmanclinic.infusionsoft.app/app/orderForms/HealthyVitaminD3K2LevelsKit?cookieUUID=303e516b-9116-409a-b2db-f927bf5126d4'} target={'_blank'} alt={'Get Result'}><button type="button" className={'getYourResult btn-primary ' + (this.state.isNextAllowed ? 'active' : '')}>Get Your Healthy <br className={'mobile'} />Vitamin D Levels Kit -></button></a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Needs;
